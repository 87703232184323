<script setup  lang="ts">
const route = useRoute();
const { t } = useNuxtApp().$i18n;
const head = useLocaleHead({ addSeoAttributes: true });
const title = computed(() => route.meta.title ? `${t(route.meta.title as string)} - Top of Travel | jungfrau.ch` : 'Top of Travel | jungfrau.ch');

useHead({
    bodyAttrs: {
        class: [
            'bg-background-1',
            'typo-copy',
            'text-brand-b',
        ].join(' '),
    },
});
</script>

<template>
    <div>
        <Html :lang="head.htmlAttrs.lang">
            <Head>
                <Title>{{ title }}</Title>
            </Head>
            <Body>
                <div class="contents">
                    <slot />
                </div>
            </Body>
        </Html>
    </div>
</template>
